import {useParams} from "react-router-dom";
import ProjectComponent from "./ProjectComponent";
import React, {useEffect} from "react";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import {v4 as uuidv4} from "uuid";
import roomList from "../data/availableRooms.json";
import initialProjectData_de from "../data/initialProjectData.json";
import initialProjectData_en from "../data/initialProjectData_en.json";
import initialProjectData_es from "../data/initialProjectData_es.json";
import initialProjectData_nl from "../data/initialProjectData_nl.json";
import initialProjectData_fr from "../data/initialProjectData_fr.json";
import initialProjectData_it from "../data/initialProjectData_it.json";
import initialProjectData_at from "../data/initialProjectData_at.json";
import controllerdesigns_de from "../data/controllerdesign.json";
import controllerdesigns_en from "../data/controllerdesign.json";
import controllerdesigns_es from "../data/controllerdesign_es.json";
import controllerdesigns_nl from "../data/controllerdesign_nl.json";
import controllerdesigns_fr from "../data/controllerdesign.json";
import controllerdesigns_it from "../data/controllerdesign.json";
import controllerdesigns_at from "../data/controllerdesign.json";
import controllerdesigns_home_de from "../data/controllerdesign_home.json";
import controllerdesigns_home_en from "../data/controllerdesign_home.json";
import controllerdesigns_home_es from "../data/controllerdesign_home_es.json";
import controllerdesigns_home_nl from "../data/controllerdesign_home_nl.json";
import controllerdesigns_home_fr from "../data/controllerdesign_home.json";
import controllerdesigns_home_it from "../data/controllerdesign_home.json";
import controllerdesigns_home_at from "../data/controllerdesign_home.json";
import schalterdesigns from "../data/schalterdesign.json";
import schalterdesigns_en from "../data/schalterdesign_en.json";
import schalterdesigns_es from "../data/schalterdesign_es.json";
import schalterdesigns_nl from "../data/schalterdesign_nl.json";
import schalterdesigns_fr from "../data/schalterdesign_fr.json";
import schalterdesigns_it from "../data/schalterdesign_it.json";
import schalterdesigns_at from "../data/schalterdesign_at.json";
import schalterdesigns_konventionell from "../data/schalterdesign_konventionell.json";
import schalterdesigns_konventionell_en from "../data/schalterdesign_konventionell_en.json";
import schalterdesigns_konventionell_es from "../data/schalterdesign_konventionell_es.json";
import schalterdesigns_konventionell_nl from "../data/schalterdesign_konventionell_nl.json";
import schalterdesigns_konventionell_fr from "../data/schalterdesign_konventionell_fr.json";
import schalterdesigns_konventionell_it from "../data/schalterdesign_konventionell_it.json";
import schalterdesigns_konventionell_at from "../data/schalterdesign_konventionell_at.json";
import schalterdesigns_home from "../data/schalterdesign_home.json";
import schalterdesigns_home_en from "../data/schalterdesign_home_en.json";
import schalterdesigns_home_es from "../data/schalterdesign_home_es.json";
import schalterdesigns_home_nl from "../data/schalterdesign_home_nl.json";
import schalterdesigns_home_fr from "../data/schalterdesign_home_fr.json";
import schalterdesigns_home_it from "../data/schalterdesign_home_it.json";
import schalterdesigns_home_at from "../data/schalterdesign_home_at.json";
import {ProjectSaveService} from "../services/ProjectSaveService";
import {useTranslation} from "react-i18next";

export default function StartComponent({isMyJung = false}){
    const {t, i18n} = useTranslation();
    const [trackingId, setTrackingId] = React.useState(null);
    const params = useParams();
    const [params2] = React.useState(loadParams(params));
    const [isExpert] = React.useState(loadIsExpert);
    const [isKonventionell] = React.useState(loadIsKonventionell);
    const [isHome] = React.useState(loadIsHome);
    const [isIFrame] = React.useState(loadIsIFrame);
    const [loadedCode, setLoadedCode] = React.useState(loadCode);
    const projectSelectList = [...getInitialProjectData()];
    const [project, setProject] = React.useState(initProject);
    const [currentStep, setCurrentStep] = React.useState(1);
    const [uiState, setUiState] = React.useState(getInitialUiState);
    const [offsetTopPos, setOffsetTopPos] = React.useState(0);
    const [scrollTop, setScrollTop] = React.useState(0);
    const [projectModified, setProjectModified] = React.useState(true);
    const [projectCode, setProjectCode] = React.useState(null);
    const [lastSum, setLastSum] = React.useState(null);

    function getInitialProjectData() {
        const lng = i18n.resolvedLanguage;
        if (lng != params2.lang) { i18n.changeLanguage(params2.lang); }
        console.log('getinitialprojectdata lng=', '"'+lng+'"', project)
        if (lng === 'en') return initialProjectData_en;
        else if (lng === 'es') {console.log('returning initialprojectdata_es');return initialProjectData_es;}
        else if (lng === 'nl') return initialProjectData_nl;
        else if (lng === 'fr') return initialProjectData_fr;
        else if (lng === 'it') return initialProjectData_it;
        else if (lng === 'at') return initialProjectData_at;
        return initialProjectData_de;
    }
    console.log('projectSelectList', projectSelectList)

    // useEffect(() => {
        if ('parentIFrame' in window) {
            window.parentIFrame.getPageInfo((info) => {
                if (offsetTopPos !== info.offsetTop && offsetTopPos === 0) { setOffsetTopPos(info.offsetTop); }
                setScrollTop(info.scrollTop);
            });
        }
    // }, []);
    function jumpTo(value) {
        // if ('parentIFrame' in window) {
        //     window.parentIFrame.scrollTo(0, yPos);
        // }
    }

    function loadParams(params){
            console.log('params', params)
        let oldUrl = params.lang == undefined;
        if(params.confType !== undefined){
            if (params.lang !== undefined) {
                if (!['de','en','nl','at','it','fr','es'].includes(params.lang)) {
                    params = {
                        ...params,
                        code: params.confType,
                        confType: params.lang,
                        lang: i18n.resolvedLanguage
                    };
                    oldUrl = true;
                }
                console.log('params after parse', params)
            }
            // let paramList = (params['*']).split('/');
            const isKonventionell = params.confType === 'schalter' || params.confType === 'switch-configurator';
            const isHome = (params.confType === 'home' || params.confType === 'home-configurator');
            const lang = params.lang ? params.lang : 'de';
            //i18n.changeLanguage(lang);
            var isiframe = false;
            try {
                isiframe =  window.self !== window.top;
            } catch (e) {
                isiframe = true;
            }
            return {
                isExpert: false, code: params.code,
                isKonventionell: isKonventionell, isHome: isHome,
                isiFrame: isiframe, isMyJung: isMyJung,
                isDebug: window.location.search === "?debug=p14",
                lang: lang,
                isOldUrl: oldUrl
            };
        }
    }

    function loadCode(){
        if(params2.code !== undefined && params2.code.length > 0){
            return params2.code;
        }
        return undefined;
    }
    function loadIsExpert(){
        if(params2.isExpert !== undefined){
            return params2.isExpert;
        }
        return undefined;
    }
    function loadIsKonventionell(){
        if(params2.isKonventionell !== undefined){
            return params2.isKonventionell;
        }
        return undefined;
    }
    function loadIsHome(){
        if(params2.isHome !== undefined){
            return params2.isHome;
        }
        return undefined;
    }
    function loadIsIFrame(){
        if(params2.isiFrame !== undefined){
            return params2.isiFrame;
        }
        return undefined;
    }

    function initProject(projectType = undefined, subProjectType = ''){
        const lng = i18n.resolvedLanguage;
        if (lng != params2.lang) { i18n.changeLanguage(params2.lang); }
        console.log('getinitialprojectdata lng=', '"'+lng+'"', project)
        GenerateRoomToAddService.resetDefaultDesignConfig();
        if (projectType === undefined && isKonventionell) { projectType = 'konventionell'; }
        if (projectType === undefined && isHome) { projectType = 'home'; }
        else if (projectType === undefined) { projectType = 'knx'; }
        if (!trackingId) {
            ProjectSaveService.saveTracking({
                trackingId: null, projectType, b2b: isMyJung, step: 1
            }, setTrackingId, params2.isOldUrl, params2.lang);
        } else {
            ProjectSaveService.saveTracking({
                trackingId: trackingId.trackingId, projectType, b2b: isMyJung,
            },undefined,params2.isOldUrl, params2.lang, trackingId);
        }
        setTimeout(() => { setProjectModified(true); setProjectCode(null); }, 100);
        let ipd;
        if (lng === 'en') ipd = initialProjectData_en;
        else if (lng === 'es') ipd = initialProjectData_es;
        else if (lng === 'nl') ipd = initialProjectData_nl;
        else if (lng === 'fr') ipd = initialProjectData_fr;
        else if (lng === 'it') ipd = initialProjectData_it;
        else if (lng === 'at') ipd = initialProjectData_at;
        else ipd = initialProjectData_de;
        console.trace('initProject', ipd[0], {...generateProject(ipd[0], projectType, subProjectType || 'neubau')})
        return {
            ...generateProject(ipd[0], projectType, subProjectType || 'neubau'),
            projectType: projectType || "knx",
            subProjectType: subProjectType || "neubau",
            selectedMusterIx: 0,
            projectName: "",
            isExpert,
            isKonventionell,
            isMyJung: isMyJung
        };
    }
    function generateProject(projectIn, projectType = undefined, subProjectType = '', justlevel = false){
        const selectedLevel = typeof projectIn.selectedLevel != "undefined" ? projectIn.selectedLevel : 1;
        const floors = projectIn.floors;
        const schalterdesign = getSchalterDesign(projectType || projectIn.projectType || "knx");
        GenerateRoomToAddService.resetDefaultDesignConfig();
        const floorToUse = floors.map( i => {
                return {
                    ...i,
                    id: i.id ? i.id : uuidv4(),
                    rooms: i.rooms.map(r => {
                        if (projectType) { r = {...r, selectedDesign: undefined}; }
                        let equipment = getDefaultEquipmentForSelectedLevel(r.type, selectedLevel);
                        if (Boolean(equipment['heizen_fußbodenheizung'])) {
                            // https://feelsmart-pro.atlassian.net/browse/FEEL-231
                            if (r.floorheatingCount < 1 && r.heatingCount > 0) {
                                equipment['heizen_heizkoerper'] = equipment['heizen_fußbodenheizung'];
                                delete equipment['heizen_fußbodenheizung'];
                            }
                        }
                        if (subProjectType === 'umbau') {
                            const skipEquipment = ['telefon_datenanschluss', 'radio_datenanschluss_sat', 'telefon_datenanschluss', 'telefonanschlusss', 'radio_datenanschluss_kabel'];
                            Object.keys(equipment).forEach(key => {
                                equipment = {...equipment, [key]: skipEquipment.includes(key) ? equipment[key] : 0};
                            });
                            const originalValues = {doorCount: r.doorCount, floorheatingCount: r.floorheatingCount, heatingCount: r.heatingCount, windowCount: r.windowCount};
                            r = {...r, doorCount: 0, floorheatingCount: 0, heatingCount: 0, windowCount: 0, originalValues: originalValues};
                        } else if (r.originalValues && !justlevel) {
                            if (r.doorCount === 0 && r.windowCount === 0 && r.floorheatingCount === 0 && r.heatingCount === 0) {
                                r = {...r, doorCount: r.originalValues.doorCount, windowCount: r.originalValues.windowCount, floorheatingCount: r.originalValues.floorheatingCount, heatingCount: r.originalValues.heatingCount};
                            }
                        } else if (!justlevel) {
                            const originalValues = {doorCount: r.doorCount, floorheatingCount: r.floorheatingCount, heatingCount: r.heatingCount, windowCount: r.windowCount};
                            r = {...r, originalValues: originalValues};
                        }
                        return {
                            ...r,
                            id: r.id ? r.id : uuidv4(),
                            selectedDesign: r.selectedDesign ? r.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign),
                            selectedEquipment: equipment
                        }
                    })
                };
            }
        );
        if (projectType && projectIn.projectType !== projectType) { projectIn = {...projectIn, projectType: projectType}; }
        return {
            ...projectIn,
            selectedLevel: selectedLevel,
            // floors: subProjectType === 'umbau' || projectIn.subProjectType === 'umbau' ? [] : floorToUse,
            floors: floorToUse,
            projectType: projectIn.projectType ? projectIn.projectType : "knx",
            isExpert,
            isKonventionell,
            isMyJung: isMyJung
        };
    }
    function getDefaultEquipmentForSelectedLevel(type, selectedLevel){
        let newObj = {};
        const list1 = roomList.filter(rl => (rl.type === type));
        const list2 = list1.length > 0 ? (list1[0]).defaultEquipment.filter(se => (se.level === selectedLevel)) : [];
        if (!list2.length) { return newObj; }
        Object.keys(list2[0].values).map(key => {
            newObj = {...newObj, [key]: list2[0].values[key]};
        });
        return newObj;
        // return list2.length > 0 ? list2[0].values : {};
    }
    function setSelectedProject(projectIn, projectType = 'knx', subProjectType = 'neubau'){
        setProject(generateProject(
            {...projectIn, projectName: project.projectName, projectType: project.projectType, subProjectType: projectIn.subProjectType},
            projectIn.projectType, projectIn.subProjectType || subProjectType
        ));
    }
    function getSchalterDesign(projectType){
        switch (projectType) {
            case "knx":
                if (params2.lang === 'en') { return schalterdesigns_en; }
                if (params2.lang === 'es') { return schalterdesigns_es; }
                if (params2.lang === 'nl') { return schalterdesigns_nl; }
                if (params2.lang === 'fr') { return schalterdesigns_fr; }
                if (params2.lang === 'it') { return schalterdesigns_it; }
                if (params2.lang === 'at') { return schalterdesigns_at; }
                return schalterdesigns;
            case "enet":
                if (params2.lang === 'en') { return schalterdesigns_en; }
                if (params2.lang === 'es') { return schalterdesigns_es; }
                if (params2.lang === 'nl') { return schalterdesigns_nl; }
                if (params2.lang === 'fr') { return schalterdesigns_fr; }
                if (params2.lang === 'it') { return schalterdesigns_it; }
                if (params2.lang === 'at') { return schalterdesigns_at; }
                return schalterdesigns;
            case "konventionell":
                if (params2.lang === 'en') { return schalterdesigns_konventionell_en; }
                if (params2.lang === 'es') { return schalterdesigns_konventionell_es; }
                if (params2.lang === 'nl') { return schalterdesigns_konventionell_nl; }
                if (params2.lang === 'fr') { return schalterdesigns_konventionell_fr; }
                if (params2.lang === 'it') { return schalterdesigns_konventionell_it; }
                if (params2.lang === 'at') { return schalterdesigns_konventionell_at; }
                return schalterdesigns_konventionell;
            case "home":
                if (params2.lang === 'en') { return schalterdesigns_home_en; }
                if (params2.lang === 'es') { return schalterdesigns_home_es; }
                if (params2.lang === 'nl') { return schalterdesigns_home_nl; }
                if (params2.lang === 'fr') { return schalterdesigns_home_fr; }
                if (params2.lang === 'it') { return schalterdesigns_home_it; }
                if (params2.lang === 'at') { return schalterdesigns_home_at; }
                return schalterdesigns_home;
            default:
                if (params2.lang === 'en') { return schalterdesigns_en; }
                if (params2.lang === 'es') { return schalterdesigns_es; }
                if (params2.lang === 'nl') { return schalterdesigns_nl; }
                if (params2.lang === 'fr') { return schalterdesigns_fr; }
                if (params2.lang === 'it') { return schalterdesigns_it; }
                if (params2.lang === 'at') { return schalterdesigns_at; }
                return schalterdesigns;
        }
    }
    function getControllerDesign(projectType){
        switch (projectType) {
            case 'home':
                if (params2.lang === 'en') { return controllerdesigns_home_en; }
                if (params2.lang === 'es') { return controllerdesigns_home_es; }
                if (params2.lang === 'nl') { return controllerdesigns_home_nl; }
                if (params2.lang === 'fr') { return controllerdesigns_home_fr; }
                if (params2.lang === 'it') { return controllerdesigns_home_it; }
                if (params2.lang === 'at') { return controllerdesigns_home_at; }
                return controllerdesigns_home_de;
                break;
            default:
                if (params2.lang === 'en') { return controllerdesigns_en; }
                if (params2.lang === 'es') { return controllerdesigns_es; }
                if (params2.lang === 'nl') { return controllerdesigns_nl; }
                if (params2.lang === 'fr') { return controllerdesigns_fr; }
                if (params2.lang === 'it') { return controllerdesigns_it; }
                if (params2.lang === 'at') { return controllerdesigns_at; }
                return controllerdesigns_de;
        }
    }
    function getInitialUiState(projectType = undefined){
        const design = getSchalterDesign(projectType || project.projectType);
        var serieIx = design.findIndex(d => d.isDefault);
        serieIx = serieIx > 0 ? serieIx : 0;
        var operatingConceptIx = design[serieIx].operatingConcepts.findIndex(d => d.isDefault);
        operatingConceptIx = operatingConceptIx > 0 ? operatingConceptIx : 0;
        var tasterIx = design[serieIx].operatingConcepts[operatingConceptIx].colorsTaster.findIndex(d => d.isDefault);
        tasterIx = tasterIx > 0 ? tasterIx : 0;
        var rahmenIx = design[serieIx].operatingConcepts[operatingConceptIx].colorsTaster[tasterIx].colorsRahmen.findIndex(d => d.isDefault);
        rahmenIx = rahmenIx > 0 ? rahmenIx : 0;

        const controller = getControllerDesign(projectType || project.projectType);
        let serieIxC = design.findIndex(d => d.isDefault);
        serieIxC = serieIxC > 0 ? serieIxC : 0;
        let operatingConceptIxC = design[serieIxC].operatingConcepts.findIndex(d => d.isDefault);
        operatingConceptIxC = operatingConceptIxC > 0 ? operatingConceptIxC : 0;
        let tasterIxC = design[serieIxC].operatingConcepts[operatingConceptIxC].colorsTaster.findIndex(d => d.isDefault);
        tasterIxC = tasterIxC > 0 ? tasterIxC : 0;
        let rahmenIxC = design[serieIxC].operatingConcepts[operatingConceptIxC].colorsTaster[tasterIxC].colorsRahmen.findIndex(d => d.isDefault);
        rahmenIxC = rahmenIxC > 0 ? rahmenIxC : 0;
        return {
            selectedSerie: serieIx,
            selectedOperationConcept: operatingConceptIx,
            selectedColorTaster: tasterIx,
            selectedColorRahmen: rahmenIx,
            selectedControllerSerie: serieIxC,
            selectedControllerOperationConcept: operatingConceptIxC,
            selectedControllerColorTaster: tasterIxC,
            selectedControllerColorRahmen: rahmenIxC,
        };
    }
    function startWithProject(projectIn){
        GenerateRoomToAddService.resetDefaultDesignConfig();
        setProject(projectIn);
        if (['knx'].includes(project.projectType)) { setCurrentStep(6); }
        else { setCurrentStep(5); }
    }

    if(loadedCode !== undefined && loadedCode.length > 0){
        const lng = i18n.resolvedLanguage;
        ProjectSaveService.loadProject(loadedCode, startWithProject, lng);
        setLoadedCode(undefined);
    }
    return <ProjectComponent
        isExpert={isExpert}
        isKonventionell={isKonventionell}
        project={project}
        setProject={setProject}
        setSelectedProject={setSelectedProject}
        initProject={initProject}
        generateProject={generateProject}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        projectSelectList={projectSelectList}
        getSchalterDesign={getSchalterDesign}
        uiState={uiState}
        setUiState={setUiState}
        getInitialUiState={getInitialUiState}
        startWithProject={startWithProject}
        isIFrame={isIFrame}
        trackingObj={trackingId}
        isHome={isHome}
        setTrackingId={setTrackingId}
        isMyJung={isMyJung}
        jumpTo={jumpTo}
        offsetTopPos={offsetTopPos}
        scrollTop={scrollTop}
        setPrCode={setProjectCode}
        prCode={projectCode}
        lastSum={lastSum}
        setLastSum={setLastSum}
        isDebug={params2.isDebug}
        isOldUrl={params2.isOldUrl}
    />

}
